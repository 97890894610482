/* main:201912 추가css */

.mainWrap {
    // margin-top: 25px;
  }
  
  .mainColumn {
      
    &.mgt50 {
      margin-top: 50px;
    }
    
    &.mgt20 {
        margin-top: 20px;
      }
  
    &.widCase1 {
      width: 45%;
      margin-right: 5%;
    }
  
    &.widCase2 {
      width: 22%;
    }
  
    &.widCase3 {
      width: 95%;
      margin-right: 5%;
    }
  
    .mainTit h3 {
      background: url("../images/ico/ico_mtit.png") no-repeat 0 0;
      padding-left: 20px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 13px;
    }
  
    .gridArea {
      height: 335px;
      background: #fff;
    }
  
    .bannerArea {
        height: 335px;
        background: #1274e3;
        padding: 10px 0 10px 14px;
    
        li {
            float: left;
            display: inline-block;
            // height: 71px;
            height: 58px;
            width: 46%;
            text-align: center;
            border-radius: 4px;
            margin-right: 4%;
            // margin-bottom: 10px;
            margin-bottom: 6px;
            background: #2c8efd;
    
            a {
                width: 100%;
                height: 100%;
                display: block;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                //   padding-top: 23px;
                padding-top: 6px;

                background: url(../images/btn/btn_main_go.png) no-repeat right center;
    
                &.pt {
                    padding-top: 16px;
                }
            }
        }
    }

    /* 4줄 */
    .bannerArea_1 {
        height: 335px;
        background: #1274e3;
        padding: 10px 0 10px 14px;
    
        li {
            float: left;
            display: inline-block;
            height: 71px;
            width: 46%;
            text-align: center;
            border-radius: 4px;
            margin-right: 4%;
            margin-bottom: 10px;
            background: #2c8efd;
    
            a {
            width: 100%;
            height: 100%;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            padding-top: 11px;
            background: url(../images/btn/btn_main_go.png) no-repeat right center;    
                &.pt {
                    padding-top: 23px;
                }
            }
        }
    }
  
    h3.notice {
      display: inline-block;
    }
  
    .nMore {
      display: inline-block;
      padding-right: 17px;
      background: url(../images/btn/btn_main_more.png) no-repeat right center;
      font-size: 14px;
      font-weight: 700;
    }
  
    .noticeArea {
      height: 335px;
      background: #f8f5c0;
      border: 1px solid #d1cc92;
      padding: 23px 30px;
  
      li {
        a {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #000000;
          font-size: 16px;
          line-height: 38px;
          max-width: 58%;
          display: inline-block;
        }
  
        span {
          color: #676769;
          font-size: 16px;
          display: inline-block;
          line-height: 38px;
          letter-spacing: -1px;
        }
      }
    }
  }
  

  
  /* main:201912 추가css 끝 */