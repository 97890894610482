@charset "utf-8";

@import './_variables.scss';
@import './_mixin.scss';

@import './reset.scss';
@import './base.scss';

@import './dx.common.scss';
@import './dx.light.compact.scss';

@import './etc.scss';
@import './component.scss';
@import './site.scss';
@import './main.scss';
@import './print.scss';
@import './mtlink.scss';

.lnb {
	position: fixed;
	top: 70px;
	left: 0;
	height: 100%;
	// width: 100%;
	z-index: 9999;
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.0);
	&::after {
		position: absolute;
		top: 70px;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		cursor: pointer;
		z-index: 9999;
	}
}
.closeWrap {
	position: absolute;
	width: 20px;
	height: 100%;
	background: #2c3a4a;
	visibility: hidden;
}
.menuBtn {
	background: transparent url(../images/btn/btn_menu_w.png) no-repeat center center;
	text-indent: -9999px;
	font-size: 0;
	position: fixed;
	left: 0px;
	top: 90px;
	width: 34px;
	height: 36px;
	z-index: 9999;
}
.menuBtn.is-visible {
	display: none;
}

.from-left.is-visible {
	.menuClose {
		display: inline-block;
		width: 34px;
		height: 36px;
		position: absolute;
		top: 20px;
		left: 204px;
		overflow: hidden;
		text-indent: -9999px;
		background: transparent url(../images/btn/btn_close.png) no-repeat center 0;
		z-index: 9999;
	}
}
a.menuClose {
	text-indent: -9999px;
	font-size: 0;
}
.ContWrap {
	width: calc(100% - 20px);
	margin-left: 20px;
	height: calc(100% - 70px);
	min-width: 650px;
	overflow: auto;
}
.ContWrap.is-visible {
	width: calc(100% - 220px);
	visibility: visible;
	position: absolute;
	left: 220px;
	margin-left: 0px;
}
.lnb.is-visible {
	visibility: visible;
	-webkit-transition: visibility 0s 0s;
	-moz-transition: visibility 0s 0s;
	transition: visibility 0s 0s;
	&::after {
		-webkit-transition: background 0s 0s;
		-moz-transition: background 0s 0s;
		transition: background 0s 0s;
	}
}
.lnb-container {
	width: 20px;
	height: 100%;
	overflow-y: auto;
	background-color: #1b2530;
	float: left;
	z-index: 9997;
}
.from-left {
	.lnb-container {
		-webkit-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		-ms-transform: translate3d(-100%, 0, 0);
		-o-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
}
.is-visible {
	.lnb-container {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-transition-delay: 0s;
		-moz-transition-delay: 0s;
		transition-delay: 0s;
		display: inline-block;
		float: left;
		width: 220px;
	}
}

.lnb-content {
	width: 100%;
	height: 100%;
	position: relative;
}
  
.tab-menu {
    input {
      display: none;
    }
  
    label {
      position: absolute;
      display: inline-block;
      width: 98px;
      height: 36px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      margin-top: 20px;
      z-index: 2;
      font-weight: bold;
  
      &.menu01 {
        left: 8px;
        background: #82868b;
        box-shadow: 5px 4px 5px #000;
      }
  
      &.menu02 {
        left: 100px;
        background: #82868b;
        box-shadow: 5px 4px 20px #000;
      }
    }
  
    .tab-content > div {
      transition: all 0.6s;
    }

    .tcon-none {
        display: none;
    }
    .tab-checked {
        background-color: #ffffff !important;
        z-index: 3;
    }    
  
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  
    .tab-content {
        // border: 1px solid #f00;
        width: 100%;
        height: calc(100% - 145px);
        box-sizing: border-box;
        // overflow-y: auto;
    
        > div {
            padding: 0;
            box-sizing: border-box;
        }
    
        top: -1px;
        position: absolute;
        top: 75px;
    }
}

.snb {
    .snb-list > li {
        min-height: 42px;
        margin-bottom: 1px;
  
        > a {
            position: relative;
            display: block;
            font-weight: 700;
            color: #fff;
            padding: 13px 40px 13px 28px;
            font-size: 13px;
            line-height: 16px;
            white-space: pre-line;
            word-break: keep-all;
            background: #323b45 url("../images/ico/ico_menu_open.png") no-repeat 90% 50%;
    
            &.sp {
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                z-index: 10;
            }
            &.active {
                color: #6DA1E8;
                font-weight: 700;
            }
        }
  
        &.on {
            ul {}
    
            > a {
                color: #ffffff;
                background: #323b45 url("../images/ico/ico_menu_close.png") no-repeat 90% 50%;
                &.active {
                    color: #6DA1E8;
                    font-weight: 700;
                }
            }
        }
    }
  
    .depth {
      display: none;
      padding: 5px 0 5px 0;
      background: #1b2530;
  
      > li {
        position: relative;
        min-height: 26px;
  
        > a {
            display: inline-block;
            //   width: 60%;
            width: 70%;
            //   margin: 2px 0px 2px 28px;
            margin: 2px 0px 2px 20px;
            font-size: 12px;
            line-height: 16px;
            padding: 5px 3px 5px 0px;
            color: #fff;
            word-break: keep-all;
            vertical-align: middle;
  
            span {
                position: absolute;
                top: 7px;
                left: 9px;
                width: 20px;
                height: 20px;
                background-position: -358px 0;
            }
        }
      }
    }
  
    .snb-list > li.on {
      > ul > li > a {
        &:hover, &:active, &:focus {
          text-decoration: underline;
          font-weight: 700;
        }
  
        &.active {
          color: #84d7fe;
          font-weight: 700;
        }
      }
  
      .depth {
        display: block;
      }
    }
  
    .depth {
      > li.on ul {
        display: block;
      }
  
      .favBtn {
        font-size: 0;
        // width: 25%;
        width: 14%;
        height: 20px;
        background: url("../images/ico/ico_fav_off.png") no-repeat 55% 0;
        margin-left: 0;
  
        &.on {
          background: url("../images/ico/ico_fav_on.png") no-repeat 55% 0;
        }
      }
    }
}

.favmenu {
	padding: 5px 0 5px 0;
	background: #1b2530;
	>li {
		position: relative;
		>a {
			display: inline-block;
			margin: 2px 0px 2px 15px;
			font-size: 12px;
			line-height: 18px;
			padding: 5px 3px 4px 0px;
			color: #fff;
			word-break: keep-all;
			&:hover {
				text-decoration: underline;
				font-weight: 700;
			}
			&:focus {
				text-decoration: underline;
				font-weight: 700;
			}
			span {
				position: absolute;
				top: 7px;
				left: 9px;
				width: 20px;
				height: 20px;
				background-position: -358px 0;
			}
		}
		>a.active {
			color: #84d7fe;
			font-weight: 700;
		}
	}
}
#Wrap {
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
	overflow: hidden;
	height: 100%;
}
#ContentWrap {
	margin: 0 auto;
	// position: relative;
    // padding: 25px 60px 25px;
    padding: 20px 20px;
}
#Header {
	position: relative;
	overflow: hidden;
	height: 70px;
	border-bottom: 1px solid #a0b0b8;
	background: #f5f7f8;
	.hdr-l {
		// margin-top: 20px;
		// a {
		// 	margin-left: 30px;
        // }
        padding: 20px 0 0 30px;
        // border: 1px solid #f00;

        .com {
            // line-height: 30px;
            margin-left: 20px;
            margin-right: 40px;
            font-size: 20px;
            color: #111111;
            font-weight: bold;
            vertical-align: middle;
        }       

        .btn-top {

            // display: none;

			background-color: #fff;
			border: 1px solid #333333;
			border-radius: 20px;
			margin-left: 20px;
			padding: 3px 20px;
			font-size: 16px;
			color: #111111;
			font-weight: bold;
			line-height: 30px;
			vertical-align: middle;
        }        
        
    }

    .userinfo {
        // margin-right: 60px;
        // margin-top: 18px;

        padding: 20px 30px 0 0;
        // border: 1px solid #f00;

        span {
            line-height: 26px;
            vertical-align: middle;
            font-size: 14px;
        }
        // a {
        //     height: 26px;
        //     width: 26px;
        //     margin-left: 15px;
        // }
        .txt {
            margin-left: 16px;
            margin-right: 5px;
            line-height: 32px;

            .nm {
                color: #111111;
                font-weight: 700;
            }
        }
        .icoBtn {
            margin-top: 7px;
        }
    }
}


.historyWrap {
	height: 32px;
	line-height: 32px;
	margin-bottom: 10px;
}
.indicate {
	font-size: 18px;
}
.tit-area {
	margin-bottom: 5px;
	height: 26px;
	line-height: 26px;
	background: url("../images/ico/ico_tit.png")no-repeat 0px 7px;
	padding-left: 13px;
	font-size: 16px;
	font-weight: bold;
}
.btn-area {
	a {
		margin-left: 5px;
		&:first-child {
			margin-left: 0;
		}
	}
}
.btnBox {
	text-align: right;
	a {
		margin-left: 5px;
		&:first-child {
			margin-left: 0;
		}
	}
}

.board_list_type {
    border-top: 1px solid #b7b7b7;
    border-left: 1px solid #b7b7b7;
    margin-bottom: 16px;

    table {
        width: 100%;
    }
    th {
        min-width: 70px;
        padding: 0 6px 0 15px;
        border-bottom: 1px solid #b7b7b7;
        border-right: 1px solid #b7b7b7;
        // line-height: 38px;
        height: 40px;
        text-align: left;
        font-weight: bold;
        background: #eff0f2;
        color: #555;
    }
    td {
        padding: 4px 8px 4px 8px;
        border-bottom: 1px solid #b7b7b7;
        border-right: 1px solid #b7b7b7;
        
        height: 40px;
        // line-height: 38px;
        vertical-align: middle;        
    }
    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        width: 100px;
        vertical-align: middle;
    }
    .tit150 {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        width: 150px;
        vertical-align: middle;
    }

    .tcon {

    }
}


.board_grid_type {
    border-top: 2px solid #000000;
    border-left: 1px solid #b7b7b7;
    margin-bottom: 16px;

    table {
        width: 100%;
    }
    th {
        min-width: 70px;
        padding: 4px 8px 4px 8px;
        border-bottom: 1px solid #b7b7b7;
        border-right: 1px solid #b7b7b7;
        // line-height: 38px;
        height: 38px;
        text-align: center;
        font-weight: bold;
        background: #eff0f2;
        color: #000000;
        vertical-align: middle;
    }
    td {
        padding: 4px 8px 4px 8px;
        border-bottom: 1px solid #b7b7b7;
        border-right: 1px solid #b7b7b7;
        
        height: 28px;
        // line-height: 38px;
        vertical-align: middle;  
        text-align: center;      
    }

    .checkbox-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #131212;
        background-color: #fff;
    }
}

.board_search_type {
	background: #fff;
    margin-bottom: 18px;
    
	table {
		width: 100%;
		border: 1px solid #b7b7b7;
		border-bottom: none;
    }
    
	th {
		min-width: 70px;
		padding: 0 6px 0 15px;
		border-bottom: 1px solid #b7b7b7;
		border-right: 1px solid #d8d8d8;
		line-height: 36px;
		text-align: left;
		font-weight: bold;
		background: #eff0f2;
	}
	td {
        padding: 0 8px 0 8px;
		border-bottom: 1px solid #b7b7b7;
		border-right: 1px solid #d8d8d8;
		height: 34px;
		&:last-child {
			border-right: 1px solid #b7b7b7;
		}
	}
	.tit {
		word-break: break-all;
		white-space: pre-wrap;
		width: 9%;
	}
}

.selSize {
	width: 100%;
}
.selSize2 {
	width: 70px;
}
.selSize3 {
	width: 30%;
}
.selSize4 {
	width: 40%;
}
.selSize5 {
	width: 50%;
}
.db-box {
	height: 26px;
}
.inp_chk_bx {
	position: relative;
	min-height: 26px;
	input[type="checkbox"] {
		border: 1px solid #707070;
		background: #fff;
		margin-top: 3px;
	}
	label {
		display: inline-block;
		position: relative;
		min-height: 14px;
		padding: 5px 0 4px 1px;
		cursor: pointer;
	}
}
span.inp_chk_bx {
	display: inline-block;
}

/* paging area */
.pageWrap {
    margin-top: 10px;
    vertical-align: middle;

    .num-Area {
        // width: 50%;
        width: 140px;
    }
    .list-Area {
        /*width:15%*/
        width: 140px;
    }
    span {
        vertical-align: middle;
        line-height: 26px;
    }
}

.page {
    // width: 60%;
    width: calc(100% - 280px);

}

/* paging */
.paging {
    text-align: center;
    margin: 0 auto;

    p {
        display: inline-block;
        margin: 0 2px;
    }

    a, strong {
        display: inline-block;
        text-align: center;
        vertical-align: top;
    }

    a {
        color: #333;
        width: 20px;
        height: 26px;
        line-height: 26px;
    }
 
    strong {
        // color: #1273e2;
        color: #333333;
        width: 20px;
        border-bottom: 2px solid #333333;
        height: 26px;
        line-height: 26px;
    } 
    
    .a-page:hover {
        color: #333;
        width: 20px;
        border-bottom: 2px solid #333;
        height: 26px;
        line-height: 26px;
    }

    .pbtn {
        width: 26px;
        display: inline-block;
        border: 1px solid #c1c1c1;
        border-radius: 3px;
    }

    .first {
        background: #fff url("../images/ico/ico_first.png") no-repeat center center;
    }
    .first-dis {
        background: #fff url("../images/ico/ico_first_dis.png") no-repeat center center;
        cursor: unset;
    }

    .last {
       background: #fff url("../images/ico/ico_last.png") no-repeat center center;
    }
    .last-dis {
        background: #fff url("../images/ico/ico_last_dis.png") no-repeat center center;
        cursor: unset;
    }

    .prev {
        background: #fff url("../images/ico/ico_pre.png") no-repeat center center;
    }
    .prev-dis {
        background: #fff url("../images/ico/ico_pre_dis.png") no-repeat center center;
        cursor: unset;
    }

    .next {
        background: #fff url("../images/ico/ico_next.png") no-repeat center center;
    }
    .next-dis {
        background: #fff url("../images/ico/ico_next_dis.png") no-repeat center center;
    }

}


.cont-split {

    .split-layout-cust {
        width: calc(100% - 68px);
    }

    .split-layout-cust.is-visible {
        width: calc(100% - 48px);
    }

    .cont-split-top {
        height: 28px;
        // line-height: 38px;

        .title {
            float: left;
            margin-top: 6px;
            img { margin-right: 10px; }
            span {
                font-size: 14px;
                font-weight: bold;
            }            
        }
        .action {
            float: right;
        }
    }

    .cont-split-left {
        padding-right: 10px;
    }

    .cont-split-right {
        padding-left: 10px;        
        // border: 1px solid #f00;
    }

}

/* login */

.login_wrap {
    .com_logo {
       // background: url("../images/etc/logo_sample.png") no-repeat 0 center;
       // background: url("../images/etc/logo_sample.png") no-repeat 0 center;
        padding-left: 110px;
    }
}
  
.login_cover {
    // border: 1px solid #f00;
    background-color: #f8f8f8;
    height: 100vh;

    position: relative;
    padding: 150px 0 50px 0;
  
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  
    .login_wrap {
      position: relative;
      z-index: 10;
      margin: 0 auto;
      width: 1090px;
    }
  
    .log_visual {
      width: 600px;
      min-height: 480px;
   //   background: url("../images/etc/photo.png") no-repeat top center;
      background-size: cover;
    }
  
    .login_cont h2 {
        margin-top: 60px;
        margin-bottom: 40px;
        padding-top: 50px;
        font-size: 14px;
        color: #999;
        // font-weight: bold;
        text-align: center;
        background: url("../images/etc/gfac_logo.png") no-repeat top center;
    }
      
    .log_body {
      width: 450px;
      min-height: 480px;
      background: #fff;
      border: 1px solid #e5e5e5;
    }
  
    .inp_wr {
        margin-left: 70px;
        margin-right: 70px;
    
        li {

            padding: 0 0 10px 0;

            .dx-placeholder {
                font-size: 14px;
            }

            input {
                font-size: 14px;
                height: 45px;
            }


            // input.inp_type {
            //   height: 45px;
            //   margin-bottom: 10px;
    
            //   &.bdclr {
            //     border: 1px solid #1273e2;
            //   }
            // }
    
            button {
                height: 45px;
                width: 100%;
                font-size: 20px;
                background: #1b2530;
                margin-top: 16px;
                margin-bottom: 8px;
                color: #fff;
                font-weight: bold;
                cursor: pointer;
            }

            span {
                color: #666; 
            }
        }
    }
}
  
.errorWrap {
    background: #fff;
    height: 100%;
}

.error_inner {
    width: 310px;
    height: 330px;
    margin: 0 auto;
    padding-top: 15%;
}

.error_txt {
    text-align: center;
    padding-top: 145px;
    background: url("../images/etc/error.png") no-repeat top center;
    margin-bottom: 50px;

    .tit {
        font-size: 26px;
        font-weight: bold;
        color: #212121;
        margin-bottom: 10px;
    }

    .culm {
        font-size: 16px;
        color: #212121;
    }
}

.error_btn {
    text-align: center;

    > button {
        width: 310px;
        cursor: pointer;
    }
}

/* popup */

#popWrap {
    height: 100vh;
}

#popHeader {
    height: 50px;
    background: #323b45;
    padding: 15px 30px 3px;
}

.pop-tit {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.pop-content {
    padding: 20px 20px;
}

.pop-cont-tit {
    
}

.div-left {
    // border: 1px solid #f00;
    float: left;
    width: 50%;
}

.div-right {
    // border: 1px solid #00f;
    padding: 0 0 0 10px;    
    float: right;
    width: 50%;
}


.div-file-sel {
    width: 44%;
    height: 140px;
    // border: 1px solid #0f0;
}

.div-img {
    width: 54%;
    padding: 4px;
    min-height: 140px;
    // line-height: 188px;    
    text-align-last: center;
    //background-color: #f4f4f4;
    border: 1px solid #e5e5e5;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #969696;
    }
}

.div-img-100 {
    width: 100%;
    padding: 4px;
    // min-height: 240px;
    // line-height: 188px;    
    text-align-last: center;
    //background-color: #f4f4f4;
    border: 1px solid #e5e5e5;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #969696;
    }
}

@media (max-width: 1199px) {
	.page {
		width: 65%;
	}
}
@media (max-width: 992px) {
	.page {
		width: 100%;
	}
}
@media (max-width: 767px) {

	.ContWrap {
		width: 100%;
		margin-left: 0;
		min-width: 650px;
	}
	.ContWrap.is-visible {
		width: 100%;
		left: 0;
    }
    
	// .leftMenu {
	// 	position: fixed;
	// 	top: 0;
	// 	left: 0;
	// 	height: 100%;
	// 	width: 100%;
	// 	&::after {
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 		background: transparent;
	// 		cursor: pointer;
	// 		z-index: 9998;
	// 	}
    // }
    
	.closeWrap {
		height: 70px;
		background: none;
		visibility: hidden;
    }
    
	.from-left.is-visible {
		>.closeWrap {
			visibility: visible;
		}
		.menuClose {
			top: 94px;
			z-index: 9998;
		}
	}
	.menuBtn.is-visible {
		left: 220px;
	}
	.menuBtn {
		background: url(../images/btn/btn_menu_w.png) no-repeat 0 0;
		background-color: none;
		position: absolute;
        // top: 0;
        top: 94px;
		left: -10px;
	}
	.menuClose {
		display: none;
	}
	.leftMenu-container {
		margin-left: 0;
	}
	.is-visible {
		.leftMenu-container {
			z-index: 1000;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.lnb {
		top: 0;
	}
	#ContentWrap {
		padding: 20px 30px 20px;
	}
	.userinfo {
		margin-right: 30px;
		margin-top: 25px;
		// margin-right: 0px;
		// margin-top: 0px;
		// width: 100%;
		// background: #eeeeee;
		// height: 35px;
        // padding-top: 5px;
        
		// .txt {
		// 	margin-right: 5px;
		// 	line-height: 20px;
		// 	margin-left: 0;
        // }
        
        .user-pic {
           display: none;
        }

        .txt {
            position: absolute;
			top: 30px;
            left: 20px;
            font-size: 12px;
        }

		.icoBtn {
			position: absolute;
			top: 20px;
			right: 15px;
		}
		>img {
			margin-right: 10px;
			padding-left: 10px;
			width: 36px;
		}
	}
	#Header {
		position: relative;
		overflow: hidden;
        height: 95px;
		div {
			margin-top: 22px;
			height: 38px;
            margin-left: 10px;
            
			a {
				margin-left: 0;
				// img {
				// 	width: 130px;
				// }
			}
			.btn-top {
				font-size: 12px;
				margin-left: 5px;
			}
        }
        
        .hdr-l {
            padding: 0 0 0 8px;

            .logo-img {
                img {
                    display: none;
                }
            }
        }


	}
	.indicate {
		font-size: 16px;
	}
	.tit-area {
		font-size: 14px;
	}
	.board_list_type {
		td {
			padding: 5px 6px 5px 6px;
		}
    }    

    /* login */
    .login_wrap {
        padding: 10px;
        > h1 {
            padding-left: 0;
            padding-top: 70px;
        }
    }

    .login_cover {
        padding: 30px 0 50px 0;
        h1 {
            font-size: 20px;
            margin-bottom: 30px;
        }
        .login_wrap {
            width: 90%;
        }
        .log_visual {
            display: none;
        }
        .login_cont h2 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .log_body {
            padding: 20px 0 0 0;
            width: 100%;
        }
        .inp_wr {
            margin-left: 30px;
            margin-right: 30px;
        }
    }    

	.page {
		width: 100%;
		margin-top: 10px;
	}
	.paging {
		text-align: center;
    }    

}

@media (max-width: 910px) and (min-width: 416px) {
	#ContentWrap {
		padding: 20px 30px 20px;
	}
	#Header {
		h1 {
			a {
				margin-left: 5px;
			}
		}
	}
}
@media (max-width: 415px) {
	#ContentWrap {
		padding: 20px 15px 20px;
	}
	.ContWrap {
		min-width: 360px;
    }
    
	.board_search_type {
		tr {
			position: relative;
			overflow: hidden;
			float: left;
			width: 100%;
        }
        
		float: left;
		width: auto;
		.tit {
			float: left;
			width: 25%;
			line-height: 40px;
			height: 40px;
			min-width: 0;
		}
		.tcon {
			float: left;
            width: 75% !important;
            padding-top: 6px;
			height: 40px;
        }  
        
    }
    
    /* paging area */
    .pageWrap {
        margin-top: 10px;
        vertical-align: middle;

        // .num-Area {
        //     // width: 50%;
        // }

        // .list-Area {
        //     /*width:15%*/
        // }

        span {
            vertical-align: middle;
            line-height: 26px;
        }
    }

    /* paging */
    .paging {
        text-align: center;
        width: 100%;

        a, strong {
            display: inline-block;
            text-align: center;
            vertical-align: top;
        }

        a {
            width: 20px;
            height: 26px;
            line-height: 26px;
        }

        strong, a:hover {
            color: #1273e2;
            width: 20px;
            border-bottom: 2px solid #1273e2;
            height: 26px;
            line-height: 26px;
        }
    }
	
}
