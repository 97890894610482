/*  =========== 
    Common
    =========== */

.link-a {
    color: #00f;
    font-weight: bold;
}

/*  ===========
    Base Content
    =========== */

.left-content {
    width: $left-side-width;
    position: absolute;
    left: 0px; top: 0px; bottom: 0px;
    background-color: #1273e2;

    .dx-treeview-toggle-item-visibility{
        line-height: 40px;
        font-size: 26px;
        color: #fff;
    }

    .dx-treeview-node .dx-treeview-item{
        background-color: #1273e2;
        &:hover{
            background-color: #1273e2;
        }
    }     

    .left-content-top {
        height: 24px;
        margin: 16px;
        .top-conf {
            position: relative;
            .conf-left {
                float: left;
            } 
            .conf-right {
                float: right;
            }
        }
    }

    .left-content-menu {
        margin: 30px 0 0 20px;
        overflow-y: auto;

        // border: 1px solid #f00;

        .menu-tree-node {
            margin-left: 8px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: bold;
            color: #fff;
        }

        .menu-tree-node-child {
            margin-left: 10px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: #fff;
        }
    }
}

.content {
    position: absolute;
    width: calc(100% - 60px);

    left: $left-side-width-close;
    top: 0px; bottom: 0px;

    overflow: hidden;

    .top {
        height: $top-width;
        line-height: $top-width;        
        // position: relative;
        padding: 0 40px 0 40px;
        box-sizing: border-box;
    
        border-bottom: 1px solid #cccccc;
        background-color: #fff;
   
        .left {
            float: left;
    
            .com-name {
                font-size: 18px;
                font-weight: bold;
                color: #333;
            }
        }
    
        .right {
            float: right;
            position: relative;

            .ico-user {
                float: left;
                margin-right: 10px;
                padding-top: 4px;
            }
    
            .auth-info {
                float: left;
                margin-right: 10px;
                font-size: 14px;
                // font-weight: bold;
                color: #333;
            }
    
            .user-info {
                float: left;
                margin-right: 20px;
                font-size: 14px;
                color: #333;
    
                .user-name {
                    font-weight: bold;
                    color: #1273e2;
                }
            }
    
            .top-button {
                float: right;
                padding-top: 4px;
            }
        }
    }
 
    .body-content {  
        position: relative;
        display: block;
        bottom: 0px;
        padding: 20px 40px 0 40px;
        // background-color: #0f0;

        .list-top {
            width: 100%;
            height: 34px;
            line-height: 34px;
            margin-bottom: 10px;
            position: relative;
            
            .path {
                float: left;
                font-size: 16px;
                .title{
                    font-weight: bold;
                }
            }            
            .action {
                float: right;
            }   
        }

        .list-search {
            border-top: 1px solid #d3d3d3;
            border-bottom: 1px solid #d3d3d3;
            

            overflow: hidden;
            position: relative;
            background-color: #fff;

            
            .line-bottom {
                border-bottom: 1px solid #ebebeb;
                // border-bottom: 1px solid #f00;
            }

            .srch-rows {
                width: 100%;
                display: inline-flex;

                .srch-item {
                    float: left;
    
                    .title {
                        display: inline-block;
                        width: 100px;
                        min-width: 70px;
                        height: 36px;
                        line-height: 36px; 
                        text-align: left;
                        padding-left: 15px;
                        font-weight: bold;
                        // border: 1px solid #ebebeb;
                        background-color: #f5f7fb;
                    }
    
                    .item {
                        display: inline-block;
                        padding: 0 8px;
                    }
    
                }
            }

            table {
                width:100%;
                word-break: break-all;	
                table-layout: fixed;
                border-collapse: collapse;                
                th {
                    height: 36px;
                    line-height: 36px;
                    text-align: left;
                    padding-left: 15px;
                    border: 1px solid #ebebeb;
                    background-color: #f5f7fb;
                }
                td {
                    height: 36px;
                    line-height: 36px;
                    padding: 0 6px 0 6px;
                    border: 1px solid #ebebeb;
                    background-color: #fff;
                }
            }
        }
        
        .list-grid-top {
            width: 100%;
            height: 28px;
            line-height: 28px;
            margin-top: 20px;
            position: relative;
            .title {
                float: left;
                img { margin-right: 10px; }
                span {
                    font-size: 14px;
                    font-weight: bold;
                }            
            }
            .action {
                float: right;
            }
            
        }

        .cont-split {
            // border: 2px solid #00f;

            .split-layout-cust {
                width: calc(100% - 80px);
            }

            .cont-split-top {
                height: 28px;
                // line-height: 38px;

                .title {
                    float: left;
                    margin-top: 6px;
                    img { margin-right: 10px; }
                    span {
                        font-size: 14px;
                        font-weight: bold;
                    }            
                }
                .action {
                    float: right;
                }
            }

            .cont-split-left {
                padding-right: 10px;
            }

            .cont-split-right {
                padding-left: 10px;
                
                // border: 1px solid #f00;
            }

        }

    }
}

/*  ===========
    Controls
    =========== */

// .data-grid-1 {
//     // margin: 10px 0 0 0;

//     .dx-datagrid-headers {
//         color: #555;
//         font-weight: bold;            
//         -ms-touch-action: pinch-zoom;
//             touch-action: pinch-zoom;

//         background-color: #f5f7fb;

//         border-bottom: 1px solid #9194a0;
//         border-top: 2px solid #4b4e68;
//     }
// }





.dg-h-cont {
    float: left;
    width: 14px;
    height: 14px;
}

.dg-col-prod {

    // background-color: #f00;

    .prod-link {
        color: #0265da;
        font-weight: bold;

        &:hover{
            font-weight: bold;
            // border-top: 1px solid #333;
            border-bottom: 1px solid #333;
        }

    }

}

.dg-h-sort {
    float: right;
    margin-top: 2px;
    width: 14px;
    height: 14px;
}

.dg-pager {
    position: relative;
    height: 40px;
    line-height: 40px;

    // border: 1px solid #000;

    .dg-pager-left {
        float: left;
        width: 170px;

        // font-size: 13px;
        color: #333;

        // border: 1px solid #000;
    }

    .dg-pager-body {
        float: left;        
        position: relative;
        width: calc(100% - 344px);

        display: flex;
        align-items: center;
        justify-content: center;
       
        .dg-pager-btn {
            display: flex;
        }

        // .dg-pager-pages {
        //     float: left;
        // }

        .dg-pager-page {
            display: inline-block;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            // font-size: 13px;
            color: #333;

            margin: 0 2px;

            &:hover{
                font-weight: bold;
                // border-top: 1px solid #333;
                border-bottom: 1px solid #333;
            }
        }

        .dg-pager-page-cur {
            display: inline-block;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            // font-size: 13px;
            font-weight: bold;
            color: #1273e2;
            margin: 0 2px;
            // border-top: 1px solid #1273e2;            
            border-bottom: 2px solid #1273e2;            
        }

        //border: 1px solid #f00;
    }

    .dg-pager-right {
        float: right;
        position: relative;
        width: 170px;

        // font-size: 13px;
        color: #333;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        //border: 1px solid #00f;

    }
}

/*  ===========
    Buttons
    =========== */

/* #region Button */

.btn-srch {    
    @include button-common();
    background-image: url('../images/ico/ico_sch2.png');
    background-position: 10%;
    background-repeat:no-repeat;
    background-color: #1273e2;    
    &:hover{
        background-color:#0d56a8;
    }
}

.btn-action {    
    @include button-common();
    background-color: #4B4E68;
    &:hover{
        background-color:#404259;
    }
}

.btn-action-sub {    
    display: inline-block;
    // height: 24px;
    line-height: 24px;
	font-weight: bold;
	font-size: 12px;
	color: #333;
    text-align: center;
    border: 1px solid #333;
    border-radius: 3px;
    background-color: #fff;    
    &:hover{
        background-color:#a3a5bc;
    }
}

.btn-action-sub-1 {    
    display: inline-block;

    line-height: 24px;
    //font-weight: bold;
    
	font-size: 12px;
	color: #333;
    text-align: center;
    border: 1px solid #dddddd;
    border-radius: 3px;
    background-color: #fff;    
    &:hover{
        background-color:#e2e2e2;
    }
}

.btn-action-sub-2 {    
    display: inline-block;

    line-height: 16px;
    font-weight: bold;
    
	font-size: 10px;
	color:  #333;
    text-align: center;
    border: 1px solid #dddddd;
    border-radius: 3px;
    background-color: #fff;    
    &:hover{
        background-color:#e2e2e2;
    }
}

.btn-icon-menu {
    display: inline-block;
    width: 24px;
    height: 19px;
    line-height: 19px;
    background: url('../images/btn/btn_menu.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-menu_w {
    display: inline-block;
    width: 24px;
    height: 19px;
    line-height: 19px;
    background: url('../images/btn/btn_menu_w.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-menu_close {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: url('../images/btn/btn_close.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-menu_conf {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: url('../images/btn/btn_set.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-logout {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;    
    background: transparent url("../images/btn/btn_logout.png") no-repeat center center;
    // background: url('../images/btn/btn_logout.png');
    // background-position: 50%;
    // background-repeat:no-repeat;
}

.btn-icon-help {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: url('../images/btn/btn_help.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-remote {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: url('../images/btn/btn_set.png');
    background-position: 50%;
    background-repeat:no-repeat;
}


.btn-icon-file {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: url('../images/ico/a_file.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-doc {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: url('../images/ico/a_doc.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-img {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: url('../images/ico/a_img.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-close-1 {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border: 1px solid #5d465d;
    border-radius: 2px;
    background-color: #fff;
    background: url('../images/btn/btn_close_1.png');
    background-position: 50%;
    background-repeat:no-repeat;

    &:hover{
        background-color:#f2f2f2;
    }  
}

.btn-icon-plus-1 {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border: 1px solid #5d465d;
    border-radius: 2px;
    background-color: #fff;
    background: url('../images/btn/btn_plus_1.png');
    background-position: 50%;
    background-repeat:no-repeat;

    &:hover{
        background-color:#f2f2f2;
    }  
}

.btn-icon-print {
    @include button-icon();    
    background: url('../images/ico/ico_print.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-xls {
    @include button-icon();    
    background: url('../images/ico/ico_xls.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-first {
    @include button-icon();    
    background: url('../images/ico/ico_first.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-first-dis {
    @include button-icon-dis();    
    background: url('../images/ico/ico_first_dis.png');
    background-position: 50%;
    background-repeat:no-repeat;
    cursor: unset;
}


.btn-icon-last {
    @include button-icon();    
    background: url('../images/ico/ico_last.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-last-dis {
    @include button-icon-dis();    
    background: url('../images/ico/ico_last_dis.png');
    background-position: 50%;
    background-repeat:no-repeat;
    cursor: unset;
}

.btn-icon-pre {
    @include button-icon();    
    background: url('../images/ico/ico_pre.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-pre-dis {
    @include button-icon-dis();    
    background: url('../images/ico/ico_pre_dis.png');
    background-position: 50%;
    background-repeat:no-repeat;
    cursor: unset;
}

.btn-icon-next {
    @include button-icon();    
    background: url('../images/ico/ico_next.png');
    background-position: 50%;
    background-repeat:no-repeat;
}

.btn-icon-next-dis {
    @include button-icon-dis();    
    background: url('../images/ico/ico_next_dis.png');
    background-position: 50%;
    background-repeat:no-repeat;
    cursor: unset;
}

.btn-icon-pop-close {
    width: 20px;
    height: 20px;
    text-indent: 9999px;
    background: transparent url("../images/btn/btn_pop_close.png") no-repeat center center;
    cursor: pointer;
}

.btn-icon-site-home {
    display: inline-block;
    width: 56px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #fff;
    border-radius: 4px;
    // text-indent: 9999px;
    background: transparent url("../images/btn/btn_site_home.png") no-repeat center center;
    cursor: pointer;

    &:hover{
        border-color:#ffff00;
    }  
}

.btn-icon-site-reload {
    display: inline-block;
    width: 50px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #fff;
    border-radius: 4px;
    // text-indent: 9999px;
    background: transparent url("../images/btn/btn_site_reload.png") no-repeat center center;
    cursor: pointer;

    &:hover{
        border-color:#ffff00;
    }  
}

.btn-icon-site-close {
    display: inline-block;
    width: 50px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #fff;
    border-radius: 4px;
    // text-indent: 9999px;
    background: transparent url("../images/btn/btn_site_close.png") no-repeat center center;
    cursor: pointer;

    &:hover{
        border-color:#ffff00;
    }  
}

/* #endregion */



/*  ===========
    Etc
    =========== */

.inline-block {
    // border: 1px solid #f00;
    display: inline-block;
}

.left-side-open {
    left: $left-side-width;
    width: calc(100% - 290px);
}

.left-side-width-close {
    width: $left-side-width-close;
}

.hidden {
    display: none;
    visibility: hidden;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mar-top-2 {
    margin-top: 2px;
}

.mar-top-10 {
    margin-top: 10px;
}

.mar-top-20 {
    margin-top: 20px;
}

.mar-right-6 {
    margin-right: 10px;
}
.mar-right-10 {
    margin-right: 10px;
}

.mar-left-6 {
    margin-left: 10px;
}
.mar-left-10 {
    margin-left: 10px;
}

.pad-top-6 {
    padding-top: 6px;
}

.font-bold {
    font-weight: bold;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}



/*  ===========
    Components & Controls
    =========== */

/* #region DevExtreme etc */

.dx-datagrid .dx-data-row > td.bullet {
    padding-top: 0;
    padding-bottom: 0;
}

.dx-viewport:not(.dx-theme-ios7) .dx-fieldset {
    width: 520px;
    margin: 30px auto;
}

.dx-viewport:not(.dx-theme-ios7) .dx-fieldset:first-child {
    margin-top: 120px;
}

.dx-viewport:not(.dx-theme-ios7) .dx-fieldset-header {
    font-size: 16px;
}

.dx-viewport:not(.dx-theme-ios7) .dx-field {
    display: inline-block;
    margin-right: 20px;
}

.dx-viewport:not(.dx-theme-ios7) .dx-field-value:not(.dx-widget) > .dx-button {
    float: none;
}

.dx-viewport:not(.dx-theme-ios7) .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
.dx-viewport:not(.dx-theme-ios7) .dx-field-label {
    float: none;
    width: 100%;
}

.dx-viewport:not(.dx-theme-ios7) .dx-field-label {
    padding-left: 0;
}

.send .dx-button-content .dx-icon {
    font-size: 18px;
}

.dx-datagrid-header-panel .dx-toolbar {
    display: none;
}

/* #endregion */

/* #region Splitter Layout */

.splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
}

.splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    // overflow: auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
}

.splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    /* height: 100%; */
    cursor: col-resize;
    background-color: #ccc;
}

.splitter-layout .layout-splitter:hover {
    background-color: #bbb;
}

.splitter-layout.layout-changing {
    cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
    flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
} 

/* #endregion */
