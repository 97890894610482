@mixin button-common() {

    display: inline-block;
    height: 32px;
    line-height: 32px;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
	text-align: center;
    border-radius: 3px;

  }

  @mixin  button-icon {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #5d465d;
    border-radius: 2px;
    background-color: #fff;

    &:hover{
        background-color:#f2f2f2;
    }      
  }

  @mixin  button-icon-dis {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #ababab;
    border-radius: 2px;
    background-color: #fff;
  }
  
  @mixin inline-center {
    display: inline-flex;
    align-items: center;
  }