.data-grid-1 {
    // margin: 10px 0 0 0;

    // border: 1px solid #f00;

    // border-top: 1px solid #9194a0;
    // border-bottom: 1px solid #9194a0;    
    border-bottom: 1px solid #d8d8d8;
    // border-top: 2px solid #2c3a4a;
    // border-right: 1px solid #d8d8d8;

    background-color: #fff;

    .dx-datagrid-headers {
        color: #111;
        // font-weight: bold;            
        -ms-touch-action: pinch-zoom;
            touch-action: pinch-zoom;

        background-color: #eff0f2;
        // border-bottom: 1px solid #9194a0;
        // border-top: 2px solid #4b4e68;
    }

    .dx-datagrid-rowsview {
        border-bottom: 0px;
    }    

    .dx-datagrid-headers {
        border-top: 2px solid #2c3a4a;
    }

    .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
        font-weight: 600;
        text-align: center !important;

        // border-bottom: 1px solid #a5a5a5;
        // &:first-child {
        //     border-top: 2px solid #2c3a4a;
		// }
    }

    
}

.data-tree-1 {
    // margin: 10px 0 0 0;
    border-bottom: 1px solid #d8d8d8;
    border-top: 2px solid #2c3a4a;
    border-right: 1px solid #d8d8d8;

    background-color: #fff;
    

    .dx-treelist-headers {
        color: #111;
        // font-weight: bold;            
        -ms-touch-action: pinch-zoom;
            touch-action: pinch-zoom;

            background-color: #eff0f2;

        // border-bottom: 1px solid #9194a0;
        // border-top: 2px solid #4b4e68;
    }

    .dx-treelist-headers .dx-treelist-table .dx-row>td {
        font-weight: 600;
        text-align: center !important;
    }
}

/* #region FileUploadBox */

.fileupbox {

    margin: 4px 0;
    // padding: ;
    .dx-fileuploader-input-wrapper {
        padding: 8px 0;
    }

    .dx-fileuploader-wrapper {
        padding: 0;
    }

    .list-area {
        
        padding: 0px 8px;

        .list-item {

            position: relative;
            height: 30px;
            line-height: 30px;

            .file-name {

                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;

                .file-link {
                    color: #000;
                    font-weight: 500;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:hover{     
                        font-weight: 600;                   
                        text-decoration: underline;
                        cursor: pointer;
                    }  
                }
            }

            .file-action {
                padding: 0 0 0 8px;
            }
        }
    }
}

/* #endregion */

/* #region Bad Amount */

.bad-comp {

    .tot {
        float: left;
        padding: 8px 6px 0 0;
        // font-size: 14px;

        .amt {
            // font-size: 14px;
            font-weight: bold;
            margin-right: 4px;
        } 
        .unit {
            // font-size: 14px;   
            margin-right: 4px;         
        } 
    }

    .dtl {
        float: left;
        padding-top: 2px;

        .row {
            li {
                height: 30px;
                float: left;
                display: flex;
                align-items: center;
                padding: 2px;
            }
            .c1 {
                width: 160px;
            }
            .c2 {
                width: 90px;
            }
            .c3 {
                width: 60px;
            }
        }
    }
}

/* #endregion */

/* #region Inspect Item */

.insp-item {
    
    table {
        width: 100%;
    }
    th {
        // width: 120px;
        // min-width: 70px;
        padding: 0 6px 0 15px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        line-height: 36px;
        text-align: left;
        font-weight: bold;
        background: #f5f7fb;
        color: #555;
    }
    td {
        // padding: 0 8px 0 8px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        // line-height: 36px;
    }
    .item {
        width: 120px;
    }
    .stnd{
        // width: 160px;
    }
    .samp{
        width: 100px;
    }
    .goodBad{
        width: 100px;
    }
    .tit {
        // word-break: break-all;
        // white-space: pre-wrap;
        // // width: 9%;
    }

}

/* #endregion */

.prod-box {
    ul {
        
        li {
            float: left;
            display: flex;
            align-items: center;
        }
    }
}

.ul-row {
    display: inline-flex;
    
    li {
        float: left;
        display: flex;
        align-items: center;
        padding: 0 2px;
        height: 34px;
    }
}

.insp-col {
    padding: 4px 2px 0 2px;
    width: 100%;
    height: 60px;
    // line-height: 60px;    

}

.insp-col-act {
    cursor: pointer;
    border: 1px solid #979797;

    &:hover {
        background-color: #d1d1d1;
    }
}

.dychk-cell {
    margin: 2px;

    border: 1px solid #f00;
}

.cal-vw {
    border-top: 1px solid #d3d3d3;

    table {
        width: 100%;
    }
    th {
        min-width: 70px;
        padding: 0 6px 0 15px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        background: #f5f7fb;
        color: #555;
    }
    td {
        // padding: 0px 8px 0 8px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        
        height: 120px;
        // line-height: 120px;

        vertical-align: top;

        .wk-seq {
            width: 100%;
            text-align: center;
            span {
                font-size: 14px;
                font-weight: bold;
                color: #6e6e6f;
            }
        }

        .dy-cell {
            width: 100%;
            // height: 120px;
            // line-height: 120px;
            // border: 1px solid #f00;
            padding: 4px 8px;

            .disp-dy {
                // border: 1px solid #0f0;
                height: 30px;
                line-height: 30px;

                span {
                    font-size: 14px;
                    font-weight: bold;
                }
                
                .diff-mon {
                    color: #999;    
                }

                .today {
                    color: #00f;
                    font-size: 16px;

                    // height: 30px;
                    // width: 30px;
                    // /* background-color: #bbb; */
                    // border: 1px solid #f00;
                    // border-radius: 50%;
                    // display: inline-block;
                }
            }

            .disp-info {
                // height: 110px;
                // line-height: 110px;
                text-align: center;
                // padding-top: 30px;                
                // border: 1px solid #00f;                
                ul {
                    margin-top: 30px;
                    // border: 1px solid #f00;
                    li {
                        padding: 4px 0;
                    }
                }

                .btn-chk {
                    color: #828282;
                    // font-weight: 700;
                    font-size: 14px;
                    &:hover{
                        // color: #585858; 
                        font-weight: bold;   
                        text-decoration: underline;                    
                    }
                }
                
                .good {
                    color: #00f !important;
                }

                .bad {
                    color: #f00 !important;
                }

                

            }

        }
    }

    .b-left {
        border-left: 1px solid #d3d3d3;
    }

}

.editable-outline {
    width: 100%;
    height: 28px;

    border: 1px solid #c1c1c1;
    border-radius: 4px;

    padding-top: 4px;

    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
} 

.editable-cell {
    width: 100%;
    height: 38px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
} 