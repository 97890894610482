$primary-color: #333;

$window-width: 100vw;
$window-height: 100vh;

$top-width: 50px;
$header-height: 70px;
$left-side-width: 290px;
$left-side-width-close: 60px;

$font-stack: 'Malgun Gothic', 'Helvetica Neue', 'Segoe UI', Helvetica, Verdana, sans-serif;
