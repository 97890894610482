@media print {
    .no_print {
        display: none;
    }
}

#printWrap {
    height: 100%;
    // background-color: #fff;
}

#printHeader {
    height: 50px;
    background: #0a2e65;
    padding: 15px 30px 3px;
}

.print-tit {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.print-content {
    padding: 0;
    // background-color: #fff;

    .action {
        height: 40px;
        margin: 10px 20px 0 0;        
    }
}

.bottom-mg {
    height: 20px;
}

.print_type {

    width: 210mm;
    // height: 297mm;

    page-break-after:always;

    border: 1px solid #fff;

    // border: 1px solid #f00;  
    
    // .endline {
    //     page-break-before: always
    // }

    .subTitle {
        margin: 10px 0 0 0;
        font-size: 14px;
        font-weight: 600;
    }

    table {
        width: 100%;
        margin: 6px 0;
    }

    th {
        border: 1px solid #3c3c3c;
        height: 36px;
    }
    td {
        border: 1px solid #3c3c3c;   
        height: 36px;     
    }

    .noboder {
        border: 0;
    }

    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        vertical-align: middle;
        background: #ccc;
        font-weight: 600;
    }    
    .tcon {
        text-align: center;
        vertical-align: middle;
        padding: 2px 4px;

        background-color: #fff;
    }

    // 제품식별표
    .prod-table {

        margin: 20px 0 0 0;
        border: 2px solid #3c3c3c;   

        td {
            border: 1px solid #3c3c3c;   
        }

        .tit_1 {    // top tile
            height: 220px;
            word-break: break-all;
            white-space: pre-wrap;
            vertical-align: middle;
            text-align: center;
            font-weight: 500;
            font-size: 52px;
            text-decoration: underline;
        }
        .tit_2 {  // bottom company name
            height: 140px;
            white-space: pre-wrap;
            vertical-align: middle;
            text-align: center;
            font-weight: 500;
            font-size: 36px;
        }

        .tcon_1 {   // 
            height: 110px;
            width: 140px;
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;      
            font-weight: 500;
            font-size: 36px;      
    
        }       
        .tcon_2 {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;
            font-size: 36px;      
        } 
    }


    // ** 거래명세서

    .deli-sender {

        td {
            border: 1px solid #f00;
            height: 42px;
            
        }

        .tit_ {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;
            color: #f00;
            background-color: #fff;
        }

        .tcon_ {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;
    
            background-color: #fff;
        }
       
    }

    .deli-rec {

        td {
            border: 1px solid #00f;
            height: 42px;
           
        }

        .tit_ {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;
            color: #00f;
            background-color: #fff;

            // font-size: 14px;
        }

        .tcon_ {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;
    
            background-color: #fff;
        }       
    }


     // **
 
    .esit-sub-tcon {
        text-align: left;
        vertical-align: middle;
        height: 26px;
        padding: 2px 8px;
        border: 0;
        font-size: 12px;
        font-weight: 500;
    }

    .esit-tit {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        height: 50px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;   
        padding-top: 6px;
    }

    .esit-subtit {
        // border: 1px solid #000;
        // border-top: 1px solid #6c6c6c;
        height: 30px;
        font-size: 14px;
        padding: 4px 8px 0 8px;        
    }

    .esit-cont {
        
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;

        th {
            font-size: 14px;
            font-weight: bold;
            padding-top: 6px;
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        td {
            text-align: center;
            vertical-align: middle;
            padding: 2px 4px;    
            // border: 1px solid #000;
            border: 0;
            // border-right: 0;
        }

        .total {
            font-size: 14px;
            font-weight: bold;
        }

        .b-t {
            border-top: 1px solid #000;
        }

        .b-b {
            border-bottom: 1px solid #000;
        }

        .b-l {
            border-left: 1px solid #000;
        }
        .b-r {
            border-right: 1px solid #000;
        }
    }

    .esit-bottom {
        // border: 1px solid #000;
        border-bottom: 1px solid #000;
        height: 150px;
        width: 100%;

        .esit-stamp {

            padding: 10px 60px 0 0;

            .stamp-tit {
                height: 30px;
                font-size: 14px;
                font-weight: bold;
                text-align: center;  
                padding-top: 2px;
            }
        }
    }

    .order-bottom {
        position: relative;
        // border: 1px solid #f00;
        border-bottom: 1px solid #000;
        height: 348px;
        width: 100%;

        .bottom-l {
            // border: 1px solid #0f0;
            float: left;
            width: 50%;            
        }

        .bottom-r {
            // border: 1px solid #00f;
            float: right;
            width: 50%;

            padding: 0 10px;
        }

        .btm-tit {
            text-align: center;
            vertical-align: middle;
            height: 32px;
            padding: 2px 4px;
            font-size: 14px;
            font-weight: bold;
        }

        .btm-con {
            text-align: left;
            vertical-align: middle;
            height: 32px;
            padding: 2px 4px;
            // font-size: 14px;
            // font-weight: bold;
        }

        .btm-info {
            text-align: center;
            vertical-align: middle;
            font-size: 14px;

            .bold {
                font-weight: bold;
            }
        }

        .btm-rule {            
            padding: 4px 0 0 0;
            td {
                border: 0;
                padding-bottom: 4px;
            }
        }

    }

    .print-bottom {       

        position: absolute;
        // top: 770px;
        left: 20px;
        width: calc(100% - 48px);

        table {
            border: 0;
            margin: 0;
        }

        td {
            height: 28px;
            font-size: 14px;
            font-weight: bold;
            vertical-align: middle;
            padding: 2px 4px;
            border: 0;
        }
        
    }

}

.endline {
    page-break-before: always
}

.print_label {

    // width: 86.20mm;
    // height: 50.70mm;

    width: 90mm;
    height: 50mm;    

    padding: 4mm 0 0 2mm;
    
    // border: 1px solid #f00;

    table {
        width: 100%;
        // margin: 6px 0;
    }

    th {
        border: 1px solid #3c3c3c;
        height: 34px;
    }
    td {
        border: 1px solid #3c3c3c;   
        height: 34px;     
    }
    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        vertical-align: middle;
        background: #ccc;
        font-weight: 600;
    }
    .tcon {
        text-align: center;
        vertical-align: middle;
        padding: 2px 4px;
    }
}

.print_xr {

    padding: 0px 10px;
    border: 1px solid #fff;
    width: 297mm;
    height: 206mm;

    font-size: 10px;

    table {
        width: 100%;
        margin: 4px 0;
    }

    th {
        border: 1px solid #3c3c3c;
        height: 26px;
    }
    td {
        border: 1px solid #3c3c3c;   
        height: 26px;     
    }

    .top_tit {   
        font-size: 18px;
        text-align: center;
        vertical-align: middle;
        
        font-weight: 700;
    }

    .chart {   
        padding: 8px;
        vertical-align: middle;
    }

    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        vertical-align: middle;
        background: #ccc;
        font-weight: 600;
    }

    .tit_apv {
        word-break: break-all;
        white-space: pre-wrap;
        vertical-align: middle;
        background: #e0e0e0;
        font-weight: 600;
    }

    .tcon {
        text-align: center;
        vertical-align: middle;
        padding: 2px 4px;
    }

    .tcon_apv {
        height: 40px;
    }

    .cpk {
        font-size: 12px;

        th {
            height: 36px;
        }

        td {
            height: 36px;
        }
    }

    .chart_left {
        padding: 14px 0 0 10px;
        font-size: 12px;

        li {
            height: 30px;
        }

        .chart_tit {
            line-height: 30px;
            font-size: 14px;
            font-weight: 600;
        }

        .i_tit {
            width: 40px;
        }

        .i_data {
            font-weight: 600;
        }
    }

}