@charset "utf-8";

/* reset */

html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;

    font: 100% $font-stack;
    font-size: 12px;
    color: $primary-color;

    font-weight: 400;
    font-family: Malgun Gothic, "맑은 고딕" , Dotum, "돋움", Helvetica, AppleSDGothicNeo, sans-serif;

    letter-spacing: -0.01em;
    // background-color: black;
}

* {
  box-sizing: border-box;
  moz-box-sizing: border-box;
  webkit-box-sizing: border-box;
  &:after, &:before {
    box-sizing: border-box;
    moz-box-sizing: border-box;
    webkit-box-sizing: border-box;
  }
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, thead, tbody, tr, th, td, form, fieldset, legend, input, textarea, button {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

img {
  border: none;
}

table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
}

legend {
  display: none;
  visibility: hidden;
}

img, fieldset, button {
  border: 0;
  vertical-align: middle;
}

ul, ol, li {
  list-style: none;
}

em, address {
  font-style: normal;
}

hr {
  display: none;
  visibility: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block;
  margin: 0;
  padding: 0;
}

form {
  margin: 0;
  padding: 0;
}

fieldset {
  border: none;
}

caption {
  display: none;
}

// select option {
//   padding: 4px 0;
// }

.ui-button {
  background: transparent !important;
  border: none !important;
}

a {
//   color: #333;
  text-decoration: none;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

div {
  appearance: none;
  moz-appearance: none;
  webkit-appearance: none;
}

.sp {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
  font-size: 1px;
  line-height: 1000px;
  white-space: nowrap;
}

.fixed {
  table-layout: fixed;
}

/* hide */

.hide {
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
}

.clearfix {
  &:before {
    clear: both;
    content: '.';
    display: block;
    height: 0px;
    visibility: hidden;
  }
  &:after {
    clear: both;
    content: '.';
    display: block;
    height: 0px;
    visibility: hidden;
    clear: both;
  }
}

.clfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0px;
  visibility: hidden;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
}

.blind {
  left: -3721em;
  position: absolute;
}

.fl-n {
  float: none !important;
}

.fl-l {
  float: left !important;
}

.fl-r {
  float: right !important;
}

.al-l {
    text-align: left !important;
}

.al-c {
  text-align: center !important;
}

.al-r{
    text-align: right !important;
}

/*  text */

.ftstyle1 {
  font-weight: 700;
}

.req {
  color: #e3223d !important;
  font-weight: 700 !important;
}

/* width */

.w10p {
  width: 10% !important;
}

.w15p{
    width: 15% !important;
}

.w20p {
  width: 20% !important;
}

.w25p {
    width: 25% !important;
}

.w30p {
  width: 30% !important;
}

.w40p {
  width: 40% !important;
}

.w50p {
  width: 50% !important;
}

.w60p {
  width: 60% !important;
}

.w70p {
  width: 70% !important;
}

.w80p {
  width: 80% !important;
}

.w90p {
  width: 90% !important;
}

.w100p {
  width: 100% !important;
}

/* margin 값 */

.mgl0 {
  margin-left: 0px;
}

.mgl2 {
    margin-left: 2px;
}

.mgl5 {
  margin-left: 5px;
}

.mgl10 {
  margin-left: 10px;
}

.mgl15 {
  margin-left: 15px;
}

.mgl20 {
  margin-left: 20px;
}

.mgl25 {
  margin-left: 25px;
}

.mgl30 {
  margin-left: 30px;
}

.mgr0 {
  margin-right: 0px;
}

.mgr2 {
    margin-right: 2px;
}

.mgr5 {
  margin-right: 5px;
}

.mgr10 {
  margin-right: 10px;
}

.mgr15 {
  margin-right: 15px;
}

.mgr20 {
  margin-right: 20px;
}

.mgr25 {
  margin-right: 25px;
}

.mgr30 {
  margin-right: 30px;
}

.mgb0 {
  margin-bottom: 0px;
}

.mgb5 {
  margin-bottom: 5px;
}

.mgb10 {
  margin-bottom: 10px;
}

.mgb15 {
  margin-bottom: 15px;
}

.mgb20 {
  margin-bottom: 20px;
}

.mgb25 {
  margin-bottom: 25px;
}

.mgb30 {
  margin-bottom: 30px;
}

.mgt0 {
  margin-top: 0px;
}

.mgt5 {
  margin-top: 5px;
}

.mgt10 {
  margin-top: 10px;
}

.mgt15 {
  margin-top: 15px;
}

.mgt20 {
  margin-top: 20px;
}

.mgt25 {
  margin-top: 25px;
}

.mgt30 {
  margin-top: 30px;
}

.pdb30 {
  padding-bottom: 30px;
}

/* color */

.clr1 {
  color: #1273e2 !important;
}

.bgc-b {
  background: #1273e2 !important;
}

/*border*/

.bdb1 {
  border-bottom: 1px solid #cccccc !important;
}

.bd1 {
  border: 1px solid #1273e2 !important;
}

/* Button */

.btn_l {
  text-align: left;
}

.btn_c {
  text-align: center;
}

.btn_r {
  text-align: right;
}

.btn{
    margin:0 auto;
    overflow:hidden;
    padding:0; 
    display:inline-block; 
    border-radius:3px; 
    text-align:center !important;
    border:1px solid #2c3a4a;
}

.btn-sz {
    line-height: 32px;
    color:#142435;
    font-weight: 700;
    padding: 0 20px;
    font-size: 14px;
}

.btn-sz-1 {
    line-height: 32px;
    color:#142435;
    font-weight: 700;
    padding: 0 20px;
    font-size: 14px;
    &:hover{
        background-color: #ccdbec;
    }
}

.btn-sz-2 {
    line-height: 32px;
    color:#ea5504;
    font-weight: 700;
    border:1px solid #ea5504;
    padding: 0 20px;
    font-size: 14px;
    &:hover{
        background-color: #fee1d1;
    }
}

.btn-sz-3 {
    line-height: 25px;
    color:#142435;
    font-weight: 700;
    padding: 0 20px;
    font-size: 14px;
    &:hover{
        background-color: #ccdbec;
    }
}
/** site */
.btn-sz-site-1 {
    line-height: 46px;
    color:#142435;
    font-weight: 700;
    padding: 0 20px;
    font-size: 22px;
    &:hover{
        background-color: #ccdbec;
    }
}

.btn-sz-site-2 {
    line-height: 70px;
    color: #142435;
    font-weight: 700;
    // background: #1273e2;
    // border:1px solid #1273e2;
    padding: 0 20px;
    font-size: 30px;

    &:hover{
        background-color: #ccdbec;
    }
}

.btn-sz-site-3 {
    line-height: 46px;
    color:#ea5504;
    font-weight: 700;
    border:1px solid #ea5504;
    padding: 0 20px;
    font-size: 22px;

    &:hover{
        background-color:#fee1d1;
    }
}

.btn-sz-site-4 {
    line-height: 32px;
    color: #bebebe;
    font-size: 16px;
    // font-weight: 700;
    // background: #38434E;
    border:1px solid #bebebe;
    border-radius: 10%;
    padding: 0 20px;
    // &:hover{
    //     background-color: #ccdbec;
    // }
}
.btn-sz-site-4_on {
    font-weight: 700;
    background: #38434E;
    border:2px solid #bebebe;
}

.btn-bsz {
    height: 26px;
    line-height: 24px;
    color: #333333;
    font-weight: 400;
    background: #fff;
    padding: 0 10px;
    border: 1px solid #4b4e68;
    border-radius: 3px;
}

.btn-isz {
    width: 28px;
    height: 26px;
    line-height: 26px;
    color: #fff;
    text-indent: -9999px;
    border: 1px solid #4b4e68;
    font-weight: 700;
    background: #fff;
}

.btn-gsz {
    height:50px; 
    line-height:50px; 
    color:#fff; 
    font-size:20px; 
    font-weight:700;
    background:#4b4e68;
    padding:0 20px;
}

// .btnSrch {
//     background: #1273e2 url("../images/ico/ico_sch2.png") no-repeat 18px center;
//     padding-left: 35px;
//     &:hover{
//         background-color:#0d56a8;
//     }
// }

.btnSrch{
    // line-height: 32px;
    color:#296fd2;
    background:#fff url("../images/ico/ico_sch2.png") no-repeat 18px center;
    padding-left:35px;
    border:1px solid #0261cf;    
    &:hover{
        background-color: #c2dffe;
    }
}

.btnSrch-site-1 {
    line-height: 46px;
    font-size: 22px;
    color:#296fd2;
    border:1px solid #0261cf; 
    background: #fff url("../images/ico/ico_sch2.png") no-repeat 18px center;
    padding-left: 35px;
    &:hover{
        background-color:#c2dffe;
    }
}

.btnSrch-selpop {
    background: #fff url("../images/ico/ico_sch2.png") no-repeat 18px center;
    padding-left: 35px;
    border:1px solid #0261cf; 
    font-size: 14px;
    line-height: 25px;
    &:hover{
        background-color:#c2dffe;
    }
}

a.btn {
  margin: 0;
  margin-left: 5px;
}

.ico-print {
  background: #fff url("../images/ico/ico_print.png") no-repeat center center;
}

.ico-exldown {
  background: #fff url("../images/ico/ico_xls.png") no-repeat center center;
}

.ico-popClose {
  width: 20px;
  height: 20px;
  text-indent: 9999px;
  background: transparent url("../images/btn/btn_pop_close.png") no-repeat center center;
  cursor: pointer;
}

.ico-stock {
  background: #fff url("../images/ico/ico_pop.png") no-repeat 10px center;
  padding-left: 25px;
}

.ico-t-scrh {
  background: #fff url("../images/ico/ico_sch.png") no-repeat center center;
  border: 1px solid #1273e2;
}

/* di{play */
.pass-y {
    color: #00f;
}

.pass-n {
    color: #f00;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (max-width: 767px) {
  body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, thead, tbody, tr, th, td, form, fieldset, legend, input, textarea, button {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
  body, input, select, div, textarea, table, button {
    color: #333333;
    font-size: 12px;
  }
}

@media (max-width: 415px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}