#siteWrap {
    height: 100%;
}

#siteHeader {
    height: 70px;
    background: #323b45;
    padding: 15px 30px 3px;
}

.site-tit {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
}

.site-span {
    font-size: 16px;
}

.site-content {
    padding: 20px 20px;
    // height: calc(100vh - 70px);
    height: 100%;
    display: flex; 
      
    
    justify-content: center;

    .main-menu {
        width: 80%;
        margin-top: 100px;

        td {
            width: 33%;
            // height: 80px;
            padding: 18px;
        }
    }

    #SiteContWrap {
        margin: 0 auto;
        position: relative;
        // padding: 25px 40px 25px;

        width: 100%;
        // border: 1px solid #f00;
    
        .tit-area {
            margin-bottom: 0;
            height: 46px;
            line-height: 46px;

            background: url("../images/ico/ico_tit.png") no-repeat 0px 17px;
            font-size: 22px;            
        }

        .s-tit-area {
            margin-bottom: 0;
            height: 46px;
            line-height: 46px;

            background: url("../images/ico/ico_tit.png") no-repeat 0px 17px;
            font-size: 18px;            
        }

        .site_search_type {
            // border-top: 1px solid #d3d3d3;
            // border-left: 1px solid #d3d3d3;
            background: #fff;
            margin-top: 10px;
            margin-bottom: 18px;
        
            table {
                width: 100%;
            }
        
            th {
                width: 120px;
                min-width: 70px;
                padding: 0 6px 0 15px;
                font-size: 16px;
                // border-bottom: 1px solid #d3d3d3;
                // border-right: 1px solid #d3d3d3;                
                // line-height: 36px;
                line-height: 50px;

                text-align: left;
                font-weight: bold;
                background: #f5f7fb;
                color: #555;
            }
        
            td {
                padding: 0 8px 0 8px;
                // border-bottom: 1px solid #d3d3d3;
                // border-right: 1px solid #d3d3d3;

                // line-height: 36px;
                line-height: 50px;
                font-size: 16px;
            }
        
            .tit {
                word-break: break-all;
                white-space: pre-wrap;
                // width: 9%;
            }

            .tcon {               
            }
                
            .bdr2-tb {
                border-top: 2px solid #d3d3d3;
                border-bottom: 2px solid #d3d3d3;
            }
        
            .bdr2-t {
                border-top: 2px solid #d3d3d3;
            }
        
            .bdr1-b {
                border-bottom: 1px solid #d3d3d3;
            }
        
            .bdr2-b {
                border-bottom: 2px solid #d3d3d3;
            }
           
        }

        .dx-dropdowneditor-icon {
            font-size: 16px;
        }

        .dx-widget {
            font-size: 16px;
        }

        .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
            height: 46px;
        }
    }

    .bad-comp {

        .dtl {
    
            .row {
                li {
                    height: 46px;
                }
                .c1 {
                    width: 220px;
                }
                .c2 {
                    width: 120px;
                }
                .c3 {
                    width: 80px;
                }
            }
        }
    }

}

.site_list_type {
    border-top: 1px solid #d3d3d3;
    border-left: 1px solid #d3d3d3;
    background: #fff;
    margin-bottom: 20px;

    table {
        width: 100%;
    }
    th {
        min-width: 70px;
        padding: 0 6px 0 15px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        background: #f5f7fb;
        color: #555;
    }
    td {
        padding: 0px 8px 0 8px;
        border-bottom: 1px solid #d3d3d3;
        border-right: 1px solid #d3d3d3;
        
        height: 50px;
        line-height: 50px;

        vertical-align: middle;
       
    }
    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        width: 100px;
        vertical-align: middle;
        font-size: 16px;
    }

    .tcon {
        font-size: 16px;
    }

    .dx-list-item {  
        font-size: 16px;  
    }
}

.site-equp-manu {
    // border-top: 1px solid #d3d3d3;
    // border-left: 1px solid #d3d3d3;
    // background: #fff;
    // margin-bottom: 20px;

    padding: 8px;

    table {
        border-top: 4px solid #4b4e68;
        border-left: 2px solid #d3d3d3;
        width: 100%;
    }    
    th {
        min-width: 70px;
        // padding: 0 6px 0 6px;
        border-bottom: 2px solid #d3d3d3;
        border-right: 2px solid #d3d3d3;        
        line-height: 36px;
        text-align: center;
        font-weight: bold;
        background: #f5f7fb;
        color: #555;
    }
    td {
        padding: 0px 4px 0 4px;
        border-bottom: 2px solid #d3d3d3;
        border-right: 2px solid #d3d3d3;        
        height: 36px;
        line-height: 36px;
        text-align: center;
        vertical-align: middle;
       
    }
    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        // width: 100px;
        vertical-align: middle;
        font-size: 16px;
    }

    .tcon {
        
        font-size: 16px;
    }

    .tcon-h {        
        font-size: 18px;
        font-weight: bold;
    }

    .tcon-d {        
        font-size: 18px;
        font-weight: bold;
    }   

    .tcon-d-m {        
        font-size: 18px;
        font-weight: bold;
        color: #00f;
    } 
   
    .dx-list-item {  
        font-size: 16px;  
    }
}

.site-equp-conn-manu {
    // border-top: 1px solid #d3d3d3;
    // border-left: 1px solid #d3d3d3;
    // background: #fff;
    // margin-bottom: 20px;

    margin: 4px 20px 4px 18px;
    padding: 16px;
    border: 2px solid #8494A6;

    border-radius: 10%;      
    .hdr {
        height: 50px;
        padding: 2px 0 2px 0;
        
        h1 {
            float: left;
            color: #fff;
            font-size: 18px;
            font-weight: bold; 
        }

        .stt {
            float: right;
            width: 80px;
            height: 30px;
            line-height: 30px;
            border-radius: 12%;
            text-align: center;
            // vertical-align: middle;            
        }
        .on {
            background: #0078f0;
            span {
                color: #fff;
                font-size: 16px;
                font-weight: bold; 
            }
            
        }
        .off {
            background: #58697a;
            span {
                color: #bebebe;
                font-size: 16px;
                font-weight: bold; 
            }
        }
          
    }

    .dds {

        .stt {
            float: right;
            width: 120px;
            height: 40px;
            line-height: 30px;
            border-radius: 3%;
            text-align: center;
            //vertical-align: middle;            
        }
        .nomal {
            background: #228ffc;
            span {
                color: #fff;
                font-size: 16px;
                font-weight: bold; 
            }
            
        }
        .high {
            background: #eedf0a;
            span {
                color: #bebebe;
                font-size: 16px;
                font-weight: bold; 
            }
        }
        .warnning {
            background: #f01f1f;
            span {
                color: #bebebe;
                font-size: 16px;
                font-weight: bold; 
            }
        }
          
    }

    table {
        width: 360px;
    }    
    td {
        
        vertical-align: middle;
        color: #bebebe;

        border-top: 1px solid #bebebe;
    }
    .prod {
        height: 26px;
        line-height: 26px;
    }
    .amount {
        height: 50px;
        line-height: 50px;
    }
    .td-end {
        border-bottom: 1px solid #bebebe;
    }

    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        // width: 100px;
        // color: #e6e6e6;
        vertical-align: middle;
        font-size: 14px;

        // background: #697c91;
    }
    .sttbar {
        
        padding: 10px 0 0 10px;
        vertical-align: middle;
    }

    .tcon {     
        padding: 0 0 0 4px;   
        vertical-align: middle;
        font-size: 14px;
        // background: #697c91;
    }

    .amt {
        color: #fff; 
        font-size: 24px;
        font-weight: "600";
    }

}

.site-equp-conn-concent {

    width: 100%;
    margin: 10px 0 0 10px;    

    .hdr {
        height: 50px;
        padding: 2px 0 2px 0;
        
        h1 {
            float: left;
            color: #fff;
            font-size: 18px;
            font-weight: bold; 
        }
    }

    .chart {
        width: 100%;
    }

}

.vertical-text { 
    transform: rotate(90deg); 
    transform-origin: left top; 
    float: left; 
}

.site-equp-data {
    // border-top: 1px solid #d3d3d3;
    // border-left: 1px solid #d3d3d3;
    // background: #fff;
    // margin-bottom: 20px;

    padding: 12px;

    table {
        // border-top: 4px solid #4b4e68;
        // border-left: 2px solid #d3d3d3;
        width: 100%;
    }    
    th {
        min-width: 70px;
        padding: 0 6px 0 15px;
        // border-bottom: 2px solid #d3d3d3;
        // border-right: 2px solid #d3d3d3;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        background: #f5f7fb;
        color: #555;
    }
    td {
        padding: 0px 8px 0 8px;
        // border-bottom: 2px solid #d3d3d3;
        // border-right: 2px solid #d3d3d3;
        
        height: 50px;
        line-height: 50px;
        text-align: center;
        vertical-align: middle;
       
    }
    .tit {
        word-break: break-all;
        white-space: pre-wrap;
        // width: 9%;
        // width: 100px;
        vertical-align: middle;
        font-size: 16px;
    }

    .tcon {
        
        font-size: 16px;
    }

    .tcon-h {        
        font-size: 18px;
        font-weight: bold;
    }

    .tcon-d {        
        font-size: 18px;
        font-weight: bold;
    }   

    .tcon-d-m {        
        font-size: 18px;
        font-weight: bold;
        color: #00f;
    } 
   
    .dx-list-item {  
        font-size: 16px;  
    }
}

.disp-now {
    padding: 4px 0 0 10px;
    h4 {
        color: #fff;
        font-size: 18px;
    }
    h2 {
        color: #fff;
        font-size: 16px;
    }
}